import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../components/mdx/page";
import { Link } from "../../components/widgets/link";
import { TableSortable } from "../../components/widgets/table";
import { usePortfolioOverview } from "../../utils/portfolio-data";
import { usePortfolioList } from "../../utils/portfolio-list";
import { formatDateTime } from "../../utils/format-helpers";
export const PortfolioStatus = () => {
  const overview = usePortfolioOverview(60 * 1000);
  const list = usePortfolioList();
  const list2 = list?.filter(info => !info.tags.includes("hidden")).filter(info => !info.tags.includes("private"));
  const table = list2 && overview && {
    columns: ["Portfolio", "Last Rebalance", "End of Backtest", "Last Update"],
    ["Portfolio"]: list2.map(i => <Link href={`/portfolios/${i.slug}/`} mdxType="Link">{i.author} {i.title}</Link>),
    ["Portfolio-TextOnly"]: list2.map(i => i.author + i.title),
    ["Last Rebalance"]: list2.map(i => overview?.[i.slug2]?.["last-rebal"]),
    ["End of Backtest"]: list2.map(i => overview?.[i.slug2]?.["date-end"]),
    ["Last Update"]: list2.map(i => formatDateTime(overview?.[i.slug2]?.["updated"])),
    ["Last Update-Sortable"]: list2.map(i => overview?.[i.slug2]?.["updated"])
  };
  const sort = {
    defaultColumn: "Last Update",
    "Portfolio": {
      alpha: true,
      ascending: true,
      sortBy: "Portfolio-TextOnly"
    },
    "Last Rebalance": {
      alpha: true,
      ascending: false
    },
    "End of Backtest": {
      alpha: true,
      ascending: true
    },
    "Last Update": {
      alpha: true,
      ascending: false,
      sortBy: "Last Update-Sortable"
    }
  };
  return <TableSortable data={table} sort={sort} mdxType="TableSortable" />;
};
export const _frontmatter = {};
const layoutProps = {
  PortfolioStatus,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Update Status`}</h1>
    <p>{`This page shows the rebalance dates and update timestamps for the portfolios and indicators tracked on `}<em parentName="p">{`TuringTrader.com`}</em>{`. Update timestamps are in your local browser time zone. Use this information to plan your rebalancing, and to determine `}<em parentName="p">{`TuringTrader.com`}</em>{`'s system availability status.`}</p>
    <PortfolioStatus mdxType="PortfolioStatus" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      